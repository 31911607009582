<template>
    <mainTable pageTitle="Produtos" :headerTitles="titles" :buttons="buttons" :items="items"/>
</template>

<script>
import { ref, onMounted, watchEffect } from 'vue';
import MainTable from '/src/components/MainTable.vue';

export default {
  name: 'CategoryPage',
  components:{
    MainTable
  },
  props:{
    refresh: Boolean
  },
  setup(props, context){
    const titles = ref([
      {id: 1, title : "Id"},
      {id: 2, title : "Imagem"},
      {id: 3, title : "Nome do Produto"},
      {id: 4, title : "Descrição do produto"},
      {id: 5, title : "Categoria do produto"},
      {id: 6, title : "Destaque"},
      {id: 7, title : "Ações"},
    ]);
    const buttons = ref([
      {name: "Editar"},
      {name: "Apagar"}   
    ]);
    const items = ref([]);

    const fetchData = async () => {
      try{
        const response = await fetch('https://konceitocriativo.pt/api/products/fetch/');
        const jsonResponse = await response.json();
        items.value = jsonResponse
      } catch(err){
        console.error('Erro a dar Fetch:', err);
      }
    }

    onMounted(() => {
      fetchData();
    });

    watchEffect(() => {
     if (props.refresh) {
       fetchData();
       // Emit the "refreshed" event with false value
       context.emit("refreshed", false);
     }
    });

    return{
      titles,
      buttons,
      items
    }
  }
};
</script>