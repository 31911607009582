<template>
  <div class="modal" v-if="show" @click="closeModal">
    <div class="modal_wrap">
      <h1 v-if="data.buttonName === 'Editar'"> Editar - {{ data.titlePage }}</h1>

      <form v-if="data.buttonName === 'Editar' && data.titlePage === 'Produtos'" ref="productForm" @submit.prevent="submitForm" enctype="multipart/form-data">
        <input type="hidden" v-model="formData.productId" name="productId">
        <div class="form_input form_file">
          <input type="file" name="productImage" ref="fileInput" @change="handleFileChange" />
          <label for="productImage">
            <div>
              <span>Arrasta a imagem</span>
              <span>ou</span>
              <span class="btn">Procurar</span>
            </div>
          </label>
          <span>{{ formData.productImage }}</span>
        </div>
        <div v-for="field in ['productName', 'productDescription', 'productCategory']" :key="field" class="form_input">
          <select v-if="field === 'productCategory'">
            <option v-for="category in categories" :key="category"> {{ category.category_name }}</option>
          </select>
          <input v-else :name="field" v-model="formData[field]" />
          <label :for="field">{{ getFieldLabel(field) }}</label>
        </div>
        <div class="form_input">
          <input type="checkbox" name="isFeatured" v-model="formData.isFeatured" />
          <label for="isFeatured">Destacar produto?</label>
        </div>

        <ActionButton title="Editar" type="submit">Confirmar</ActionButton>
        <ActionButton title="Cancelar" @click="closeModal">Cancelar</ActionButton>
      </form>

      <h2 v-else-if="data.buttonName === 'Apagar'">Tens a certeza que queres apagar o <span style="font-weight:100">{{ data.itemData.product_name || data.itemData.category_name || data.itemData.username }}</span></h2>

      <h2 v-else-if="data.buttonName === 'Adicionar'">
        <form @submit.prevent="handleNewProduct" enctype="multipart/form-data">
          <div class="form_input form_file">
            <input type="file" name="productImage" ref="fileInput" @change="handleFileChange" />
            <label for="productImage">
              <div>
                <span>Arrasta a imagem</span>
                <span>ou</span>
                <span class="btn">Procurar</span>
              </div>
            </label>
            <span>{{ formData.productImage }}</span>
          </div>

          <div class="form_input">
            <input type="text" name="productName" v-model="formData.productName" />
            <label for="productName">Nome do produto</label>
          </div>

          <div class="form_input">
            <input type="text" name="productDescription" v-model="formData.productDescription" />
            <label for="productDescription">Descrição do produto</label>
          </div>

          <div class="form_input">
            <input type="text" name="productCategory" v-model="formData.productCategory" />
            <label for="productCategory">Categoria do produto</label>
          </div>

          <div class="form_input">
            <input type="checkbox" name="isFeatured" v-model="formData.isFeatured" />
            <label for="isFeatured">Destacar produto?</label>
          </div>

          <button type="submit">Submit</button>
        </form>
      </h2>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import ActionButton from './ActionButton.vue';

export default {
  components: {
    ActionButton,
  },
  props: {
    show: Boolean,
    data: Object,
  },
  setup(props, context) {
    const fileInput = ref(null);
    const categories = ref([]);
    const formData = ref({
      productId: '',
      productImage: null,
      productName: '',
      productDescription: '',
      productCategory: '',
      isFeatured: '',
    });

    const handleFileChange = () => {
      if (fileInput.value && fileInput.value.files && fileInput.value.files.length > 0) {
        formData.value.productImage = fileInput.value.files[0].name;
      } else {
        console.error('No file selected or file input not available.');
      }
    };

    const closeModal = (e) => {
      if (e.target.classList.contains('modal')) {
        context.emit('closeModal');
      }
    };

    const updateFormData = () => {
      if (props.data.itemData) {
        const {
          id,
          product_image,
          product_name,
          product_description,
          product_category,
          product_featured,
        } = props.data.itemData;

        formData.value.productId = id;
        formData.value.productImage = product_image;
        formData.value.productName = product_name;
        formData.value.productDescription = product_description;
        formData.value.productCategory = product_category;
        formData.value.isFeatured = product_featured;
      }
    };

    const fetchCategories = () => {
      fetch('https://konceitocriativo.pt/api/category/fetch/')
        .then((response) => response.json())
        .then((data) => {
          categories.value = data;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getFieldLabel = (field) => {
      // Provide labels for form fields if needed
      const labels = {
        productName: 'Nome do Produto',
        productDescription: 'Descrição do Produto',
        productCategory: 'Categoria do Produto',
      };

      return labels[field] || field;
    };

    const submitForm = () => {
      const form = new FormData();
      form.append('productId', formData.value.productId);
      form.append('productName', formData.value.productName);
      form.append('productDescription', formData.value.productDescription);
      form.append('productCategory', formData.value.productCategory);
      form.append('isFeatured', formData.value.isFeatured);

        if (fileInput.value && fileInput.value.files && fileInput.value.files.length > 0) {
            form.append('productImage', fileInput.value.files[0]);
        }
      fetch('https://konceitocriativo.pt/api/products/update/', {
        method: 'post',
        body: form,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(form);
          if (data.status === 'success') {
            console.log('sucesso');
            context.emit('closeModal');
            context.emit('updateSuccess')
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleNewProduct = () => {
      const form = new FormData();
      form.append('productName', formData.value.productName);
      form.append('productImage', formData.value.productImage);
      form.append('productDescription', formData.value.productDescription);
      form.append('productCategory', formData.value.productCategory);
      form.append('isFeatured', formData.value.isFeatured);

      if (fileInput.value && fileInput.value.files && fileInput.value.files.length > 0) {
          form.append('productImage', fileInput.value.files[0]);
      }

      fetch('https://konceitocriativo.pt/api/products/create/', {
        method: 'post',
        body: form,
      })
      .then((response) => response.json())
      .then((data) => {
          console.log(data);
          if (data.status === 'success') {
            console.log('sucesso');
            context.emit('closeModal');
            context.emit('updateSuccess')
          }
        })
      .catch((error) => {
          console.log(error);
      });
    }

    watch(() => props.data.itemData, updateFormData, { immediate: true });

    onMounted(() => {
      fetchCategories();
      updateFormData();
    });

    return {
      categories,
      formData,
      fileInput,
      handleFileChange,
      handleNewProduct,
      closeModal,
      submitForm,
      getFieldLabel,
    };
  },
};
</script>

<style scoped>
.modal {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
}
span {
  display: block;
}
.modal_wrap {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 5px 20px;
}
.form_input {
  margin: 10px 0px;
}
.form_file {
  display: flex;
  flex-direction: column;
  position: relative;
}
.form_file input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  padding: 10px;
}
.form_file label {
  position: relative;
  border: 1px dashed #1b9ba9;
  border-radius: 6px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;
  text-align: center;
}
.form_input {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
}
.form_input input,
.form_input select,
.form_input textarea {
  padding: 5px;
}
</style>
