<template>
    <mainTable pageTitle="Utilizadores" :headerTitles="titles" :buttons="buttons" :items="items"/>
</template>

<script>
import MainTable from '/src/components/MainTable.vue';

export default {
  name: 'UsersPage',
  components:{
    MainTable
  },
  data() {
    return {
        titles:[
            {id: 1, title : "Id"},
            {id: 2, title : "Username"},
            {id: 3, title : "Data de criação"},
            {id: 4, title : "Ações"},
        ],
        items: [],
        buttons:[
            {name: "Editar"},
            {name: "Apagar"}
        ]
    };
  },
  mounted() {
    fetch('https://konceitocriativo.pt/api/users/fetch/')
    .then(response => response.json())
    .then(data => {
        this.items = data;
    })
    .catch(error => {
      console.log(error);
    });
  }
};
</script>