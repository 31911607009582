<template>
    <div id="layout">
        <ModalPopup :show="modalShow" :data="modalData" @closeModal="closeModal" @updateSuccess="handleUpdateSuccess"/>
        <sideNav/>
        <router-view class="view" @openModal="openModal" :refresh="refreshData" @refreshed="handleUpdated"/>
    </div>
</template>

<script>
import sideNav from '../components/SideNav.vue';
import ModalPopup from '../components/ModalPopup.vue'

export default {
  name: 'DefaultLayout',
  components: {
    sideNav,
    ModalPopup
  },
  data() {
    return {
      modalShow: false,
      refreshData : false,
      modalData: {},
    };
  },
  methods:{
    handleUpdateSuccess() {
      this.refreshData = true;
    },
    handleUpdated(){
      this.refreshData = false;
    },
    openModal(data){
      this.modalData = data;
      this.modalShow = true;
    },
    closeModal() {
      this.modalShow = false;
    },
  }
}
</script>
<style>
 #layout{
    display: flex;
    flex-direction:row;
    height: 100vh;
 }
 .view{
    width:100%;
    margin: 0px 20px
 }
</style>
