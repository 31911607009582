<template>
  <div id="sideNav">
    <img alt="Konceito Criativo Logo" src="../assets/logo.png">
    <div class="menu">
        <router-link to="/dashboard">Dashboard</router-link>
        <router-link to="/imagens">Imagens</router-link>
        <router-link to="/utilizadores">Utilizadores</router-link>
        <router-link to="/categorias">Categorias</router-link>
        <router-link to="/produtos">Produtos</router-link>
    </div>
  </div>
</template>

<style scoped>
 #sideNav{
    max-width:200px;
    width:100%;
    padding:20px;
    background-color: #ffffff;
 }
 img{
    max-width:100%;
    width:100%;
    margin-bottom:50px;
 }
 .menu{
    display:flex;
    flex-direction:column;
    gap: 10px;
 }
 .menu a{
    text-decoration: none;
    font-size: 1rem;
    color:inherit;
 }
 .router-link-active{
    font-weight: bold;
 }

</style>