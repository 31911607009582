<template>
    <button :data-value="title"> {{ title }}</button>
</template>

<script>
    export default {
        name: "FormActionButton",
        props: {
            title: String
        }
    }
</script>

<style scoped>
button{
    margin:5px;
    border:none;
    outline:none;
    color: inherit;
    padding:5px;
    border-radius:5px;
    cursor:pointer;
}
button[data-value="Editar"]{
    background-color:#ffc926;
    color:#FFFFFF;
    transition: background-color .5s;
}
button[data-value="Editar"]:hover{
    background-color:#cca01e;
}
button[data-value="Apagar"]{
    background-color:#ed5e68;
    color:#FFFFFF;
    transition: background-color .5s;
}
button[data-value="Apagar"]:hover{
    background-color:#ba4951;
}
button[data-value="Adicionar"]{
    background-color:#7bd78b;
    color:#FFFFFF;
    transition: background-color .5s;
}
button[data-value="Adicionar"]:hover{
    background-color:#86da94;
}
</style>