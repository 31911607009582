<template>
    <mainTable pageTitle="Categorias" :headerTitles="titles" :buttons="buttons" :items="items"/>
</template>

<script>
import MainTable from '/src/components/MainTable.vue';

export default {
  name: 'CategoryPage',
  components:{
    MainTable
  }, 
  data() {
    return {
      titles:[
            {id: 1, title : "Id"},
            {id: 2, title : "Imagem"},
            {id: 3, title : "Nome da categoria"},
            {id: 4, title : "Data de criação"},
            {id: 5, title : "Ações"},
        ],
        items: [],
        buttons:[
            {name: "Editar"},
            {name: "Apagar"}
        ]
    };
  },
  methods:{
    fetchData(){
      console.log('fetching')
      fetch('https://konceitocriativo.pt/api/category/fetch/')
      .then(response => response.json())
      .then(data => {
          this.items = data;
          console.log(data)
      })
      .catch(error => {
        console.log(error);
      });
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>