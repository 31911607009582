<template>
  <div>
    <div class="table_title">
        <h1>{{ pageTitle }}</h1>
        <ActionButton type="button" @click="openModal('Adicionar', pageTitle,item)" title="Adicionar"></ActionButton>
      </div>
    <table>
      <thead>
        <th v-for="title in headerTitles" :key="title.id">{{ title.title }}</th>
      </thead>
      <tbody>
        <template v-if="pageTitle == 'Utilizadores'">
          <tr v-for="item in items" :key="item.id">
            <td>{{ item.id }}</td>
            <td>{{ item.username }}</td>
            <td>{{ item.registration_date }}</td>
            <td><ActionButton v-for="button in buttons" :key="button.name" :title="button.name" @click="openModal(button.name, pageTitle, item)"/></td>
          </tr>
        </template>

        <template v-if="pageTitle == 'Categorias'">
          <tr v-for="item in items" :key="item.id">
            <td>{{ item.id }}</td>
            <td><img :src="'https://konceitocriativo.pt'+item.category_image"></td>
            <td > {{ item.category_name }}</td>
            <td>{{ item.registration_date }}</td>
            <td><ActionButton v-for="button in buttons" :key="button.name"  :title="button.name" @click="openModal(button.name, pageTitle,item)"/></td>
          </tr>
        </template>

        <template v-if="pageTitle == 'Produtos'">
          <tr v-for="item in items" :key="item.id">
            <td>{{ item.id }}</td>
            <td><img :src="'https://konceitocriativo.pt/assets/images/'+item.product_image"></td>
            <td > {{ item.product_name }}</td>
            <td > {{ item.product_description }}</td>
            <td > {{ item.product_category }}</td>
            <td v-if="item.product_featured == 0">Não</td>
            <td v-else>Sim</td>
            <td><ActionButton v-for="button in buttons" :key="button.name" :title="button.name" @click="openModal(button.name, pageTitle, item)"/></td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import ActionButton from './ActionButton.vue';

export default {
  name: 'MainTable',
  components:{
    ActionButton
  },
  props: {
    pageTitle : String,
    headerTitles: {
      type: Array,
      required: true
    },
    buttons: {
      type:Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  methods:{
    openModal(btnName, pageTitle, itemData) {
      console.log(btnName, pageTitle)
      const data = {
        buttonName: btnName,
        titlePage: pageTitle,
        itemData : itemData
      }
      this.$emit("openModal", data);
    },
  },
  watch:{
    items:{
      handler(){
        console.log('Items foram updated')
      },
      deep:true
    }
  }
}
</script>

<style scoped>
  .table_title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  table{
      width:100%;
      border-collapse: collapse;
  }
  table td, table th{
      position:relative;
      border:1px solid #ececed;
      padding:10px;
      background-color: #ffffff;
      color: #8b9293;
      text-align:center;
  }
  img{
    max-width:120px;
  }
</style>