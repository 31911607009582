import { createRouter, createWebHistory } from "vue-router";
import DashboardPage from '/src/views/DashboardPage.vue';
import UsersPage from '/src/views/UsersPage.vue';
import CategoryPage from '/src/views/CategoryPage.vue';
import ProductsPage from '/src/views/ProductsPage.vue';
import DefaultLayout from '/src/layouts/DefaultLayout.vue';
import ImagensPage from '/src/views/ImagesPage.vue';

const routes = [
    {
       path:'/',
       name:'Public',
       component: DefaultLayout,
       redirect: '/dashboard',
       children: [
        {
            path:'/dashboard',
            name:'Dashboard',
            component: DashboardPage,
            meta:{title:'Dashboard - Konceito Criativo'}
        },
        {
            path:'/imagens',
            name:'Imagens',
            component: ImagensPage,
            meta:{title:'Imagens - Konceito Criativo'}
        },
        {
            path:'/utilizadores',
            name:'Utilizadores',
            component: UsersPage,
            meta:{title:'Utilizadores - Konceito Criativo'}
         },
         {
             path:'/categorias',
             name:'Categorias',
             component: CategoryPage,
             meta:{title:'Categorias - Konceito Criativo'}
          },
          {
              path:'/produtos',
              name:'Produtos',
              component: ProductsPage,
              meta:{title:'Produtos - Konceito Criativo'}
           }
       ]
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})
router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})
export default router;